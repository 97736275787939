import React, { useEffect, useState } from "react";
import { db } from "./firebaseConfig";
import { collection, onSnapshot } from "firebase/firestore";
import { Table, Typography, Divider, Switch, Button, message } from "antd";
import { apiAuthEndPoint } from './constants';

const { Title } = Typography;

const UsersTable = () => {
    // State to store the fetched users data
    const [users, setUsers] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);

    // Function to copy the user id to clipboard
    const copyToClipboard = (id) => {
        navigator.clipboard.writeText(id).then(() => {
            message.success("User link copied to clipboard!");
        }).catch(() => {
            message.error("Failed to copy!");
        });
    };

    const sendEmailInvite = async (uuid) => {
        const url = 'http://localhost:5002/api/sendrsvp';
        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ uuid }),
            });

            const result = await res.json();
            message.success("Email sent to " + uuid);
            console.log(result);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    // Function to set dynamic columns based on the keys in the Firestore documents
    const setDynamicColumns = (data) => {
        // Get all unique keys from the data (including id for the doc ID)
        const allKeys = new Set();
        data.forEach(user => {
            Object.keys(user).forEach(key => allKeys.add(key));
        });

        // Dynamically generate columns from all keys
        const dynamicColumns = Array.from(allKeys).map((key) => {
            // Check if any user has a boolean value for this key
            const isBoolean = data.some(user => typeof user[key] === "boolean");

            if (isBoolean) {
                // Render a switch for boolean values
                return {
                    title: key.toUpperCase(),
                    dataIndex: key,
                    key: key,
                    render: (value) => <Switch disabled checked={value} />,
                };
            }

            // For other data types, render as normal text
            return {
                title: key.toUpperCase(),
                dataIndex: key,
                key: key,
            };
        });

        // Set columns, including the copy button column first
        setColumns([
            {
                title: "Action",
                key: "action",
                render: (_, record) => (
                    <>
                        <Button style={{margin: 5}} onClick={() => copyToClipboard(`${apiAuthEndPoint}${record.id}`)}>
                            Copy Invite
                        </Button>
                        <Button style={{margin: 5}}  onClick={() => sendEmailInvite(record.id)}>
                            Send Email
                        </Button>
                    </>
                ),
            },
            ...dynamicColumns,
        ]);
    };

    // Function to subscribe to Firestore updates using onSnapshot
    const subscribeToUsers = () => {
        const usersCollection = collection(db, "users");

        const unsubscribe = onSnapshot(usersCollection, (snapshot) => {
            const userList = snapshot.docs.map((doc) => ({
                id: doc.id, // Include document id for key
                ...doc.data(), // Spread the document data
            }));

            // Dynamically generate columns based on the user data
            if (userList.length > 0) {
                setDynamicColumns(userList);
            }

            setUsers(userList);
            setLoading(false);
        });

        // Cleanup the subscription when the component unmounts
        return unsubscribe;
    };

    useEffect(() => {
        // Subscribe to Firestore updates when component mounts
        const unsubscribe = subscribeToUsers();

        // Unsubscribe when component unmounts
        return () => unsubscribe();
    }, []);

    return (
        <div style={{ padding: "20px" }}>
            <Title level={2}>Users List ({users.length})</Title>
            <Divider />
            <Table
                dataSource={users}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={false} // Disable pagination for simplicity, you can enable if needed
            />
        </div>
    );
};

export default UsersTable;