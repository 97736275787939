import React, { useState } from "react";
import { auth } from "./firebaseConfig";
import { sendSignInLinkToEmail } from "firebase/auth";
import { Row, Col, Form, Input, Button, Alert, Card } from "antd";
import { ReactComponent as Logo } from './login.svg';

const Login = ({ onLogin }) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // New loading state

  const sendEmailLink = async (to) => {
    const response = await fetch('https://harryandrachel.sunbear.ltd/api/sendrsvp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ to }),
    });
    if (!response.ok) {
      throw new Error();
    }
  }

  const handleLogin = async (values) => {
    const { email } = values;
    try {
      setLoading(true);
      await sendEmailLink(email);
      setLoading(false);
      window.localStorage.setItem("emailForSignIn", email);
      setMessage(`Please check your email for a login link`);
      setError(""); // Clear any previous errors
    } catch (error) {
      console.error("Error sending email: ", error);
      setError("Failed to send email. Please try again.");
      setMessage(""); // Clear any previous message
    }
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }} className="fadeIn">
      <Col xs={24} sm={16} md={12} lg={8}>
        <Card style={{ textAlign: 'center' }}>
          {/* Centering the Logo */}
          {/* <div style={{ marginBottom: '20px' }}>
            <Logo style={{ width: '32px' }} />
          </div> */}
          <Logo style={{ width: '80%' }} />
          <Form layout="vertical" onFinish={handleLogin}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please enter your email!' }]}
            >
              <Input
                type="email"
                placeholder="Enter your email"
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {loading ? 'Loading...' : 'Enter'}
              </Button>
            </Form.Item>

            {/* Success message */}
            {message && (
              <Alert
                message={message}
                type="success"
                showIcon
                style={{ marginBottom: '1rem' }}
              />
            )}
            {/* Error message */}
            {error && (
              <Alert
                message={error}
                type="error"
                showIcon
              />
            )}
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
