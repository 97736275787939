// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDBZKP1NA7s4RL0mWuX95yI6ciqIneSFi0",
    authDomain: "r-s-v-p.firebaseapp.com",
    projectId: "r-s-v-p",
    storageBucket: "r-s-v-p.appspot.com",
    messagingSenderId: "321155501901",
    appId: "1:321155501901:web:7a1e3027e00f01e5003104"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
