import React, { useEffect, useState } from "react";
import { db } from "./firebaseConfig";
import { collection, query, where, getDocs, updateDoc, doc, getDoc } from "firebase/firestore";
import { debounce } from "lodash";
import { Card, List, Switch, Button, Row, Col, Form, Input, Typography, Divider, Modal, Select } from "antd";
import { CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { eventDetails, messagePlaceholder, desc } from './constants';
import { ReactComponent as StdCard } from './stdcard.svg';
import { ReactComponent as StdCardF } from './stdcardF.svg';
import ReactCardFlip from 'react-card-flip';


const Rsvp = ({ userEmail }) => {
    const [isFlipped, setIsFlipped] = useState(true);
    const [invitees, setInvitees] = useState([]);
    const [message, setMessage] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsFlipped(false);
        }, 2500);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const fetchInvitees = async () => {
            const q = query(collection(db, "users"), where("emailAddress", "==", userEmail));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const inviteesArray = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setInvitees(inviteesArray);

                const firstInvitee = querySnapshot.docs[0]?.data();
                if (firstInvitee?.message) {
                    setMessage(firstInvitee.message);
                }

                querySnapshot.docs.forEach(async (doc) => {
                    await updateDoc(doc.ref, { saveTheDateSeen: true });
                });

            } else {
                console.log("No invitees found for this user email : ", userEmail);
            }
        };

        fetchInvitees();
    }, [userEmail]);

    const handleToggleAttendance = async (inviteeDocId) => {
        try {
            const inviteeDocRef = doc(db, "users", inviteeDocId);
            const inviteeSnapshot = await getDoc(inviteeDocRef);

            if (inviteeSnapshot.exists()) {
                const inviteeData = inviteeSnapshot.data();
                const updatedInvitee = {
                    ...inviteeData,
                    canAttend: !inviteeData.canAttend,
                };
                await updateDoc(inviteeDocRef, updatedInvitee);
                setInvitees((prev) =>
                    prev.map((invitee) =>
                        invitee.id === inviteeDocId ? { ...invitee, ...updatedInvitee } : invitee
                    )
                );
            } else {
                console.error("Invitee document not found!");
            }
        } catch (error) {
            console.error("Error toggling attendance: ", error);
        }
    };

    const setResponded = async () => {
        if (invitees.length > 0) {
            invitees.forEach(async (invitee) => {
                const inviteeDocRef = doc(db, "users", invitee.id);
                try {
                    await updateDoc(inviteeDocRef, { saveTheDateResponded: true });
                    console.log("Response updated in Firestore for invitee", invitee.id);
                } catch (error) {
                    console.error("Error updating response in Firestore for invitee", invitee.id, error);
                }
            });
        }
    }

    const updateMessageInFirestore = debounce(async (newMessage) => {
        if (invitees.length > 0) {
            invitees.forEach(async (invitee) => {
                const inviteeDocRef = doc(db, "users", invitee.id);
                try {
                    await updateDoc(inviteeDocRef, { message: newMessage });
                    console.log("Message updated in Firestore for invitee", invitee.id);
                } catch (error) {
                    console.error("Error updating message in Firestore for invitee", invitee.id, error);
                }
            });
        }
    }, 500);

    const handleAddToCalendar = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/event.ics`;
        link.setAttribute('download', 'HRWedding.ics');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleGetDirections = () => {
        const { postcode } = eventDetails;
        const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(postcode)}`;
        window.open(mapsUrl, "_blank");
    };

    const showModal = () => {
        setResponded();
        setIsModalVisible(true);
    };

    const handleModalOk = () => {
        setIsModalVisible(false);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Card style={{ maxWidth: '600px', width: '100%' }}>
                <ReactCardFlip isFlipped={isFlipped}>
                    <StdCard
                        style={{ width: '100%', margin: '0 0 20px 0', borderRadius: '10px' }}
                        onClick={() => setIsFlipped((prev) => !prev)}
                    />
                    <StdCardF
                        style={{ width: '100%', margin: '0 0 20px 0', borderRadius: '10px' }}
                        onClick={() => setIsFlipped((prev) => !prev)}
                    />
                </ReactCardFlip>
                <Typography.Title level={5}>Save the Date!</Typography.Title>
                <Typography.Paragraph>{desc}</Typography.Paragraph>
                <Divider level={5}> Details </Divider>
                <List>
                    <List.Item>
                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                            <Col xs={24} sm={16}>
                                <strong>Date:</strong> {eventDetails.startDate.toLocaleDateString()}
                            </Col>
                            <Col xs={24} sm={8}>
                                <Button
                                    type="default"
                                    icon={<CalendarOutlined />}
                                    onClick={handleAddToCalendar}
                                    block
                                >
                                    Add to Calendar
                                </Button>
                            </Col>
                        </Row>
                    </List.Item>

                    <List.Item style={{ width: '100%' }}>
                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                            <Col xs={24} sm={16}>
                                <strong>Location:</strong> {eventDetails.location}
                            </Col>
                            <Col xs={24} sm={8}>
                                <Button
                                    type="default"
                                    icon={<EnvironmentOutlined />}
                                    onClick={handleGetDirections}
                                    block
                                >
                                    Get Directions
                                </Button>
                            </Col>
                        </Row>
                    </List.Item>
                </List>

                <Divider level={5}> Response </Divider>
                <List
                    dataSource={invitees}
                    renderItem={(invitee) => (
                        <List.Item>
                            <Row gutter={[16, 16]} style={{ width: '100%' }}>
                                <Col xs={24} sm={16}>
                                    <strong>{invitee.name} :</strong>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Select
                                        value={invitee.canAttend ? "canAttend" : "cannotAttend"}
                                        onChange={(value) => handleToggleAttendance(invitee.id, value === "canAttend")}
                                        style={{ width: '100%' }}
                                    >
                                        <Select.Option value="canAttend">Can Attend</Select.Option>
                                        <Select.Option value="cannotAttend">Cannot Attend</Select.Option>
                                    </Select>
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />

                <Form layout="vertical" style={{ marginTop: '20px' }}>
                    <Form.Item>
                        <Input.TextArea
                            style={{ resize: 'none' }}
                            rows={4}
                            placeholder={messagePlaceholder}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onBlur={() => updateMessageInFirestore(message)}
                        />
                    </Form.Item>
                </Form>

                <Button
                    type="primary"
                    onClick={showModal}
                    style={{ width: '100%' }}
                >
                    Save Response
                </Button>

                <Modal
                    title="Thank you"
                    open={isModalVisible}
                    onOk={handleModalOk}
                    onCancel={handleModalOk}
                >
                    <p>Thanks for your response!</p>
                </Modal>
            </Card>
        </div>
    );
};

export default Rsvp;
